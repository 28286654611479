<template>
	<AtomsModal @close="closePopup" class="pay-modal">
		
<FormKitLazyProvider config-file="true" :default-config="false">
<FormKit @submit="onSubmit" type="form" :actions="false" :incomplete-message="false" #="{ state, value }">
			<div class="pay-modal-title-box">
				<h2 class="pay-modal-title">{{ $t('product.pay_modal.title') }}</h2>
				<DisputeDropdown />
			</div>
			<div class="card-product">
				<div class="img-box">
					<NuxtImgC :src="currentProduct?.image_id" alt="" :fallback="fallbackImg" sizes="166px lg:105px" />
				</div>
				<div class="text-box">
					<p class="title"> {{ currentProduct?.name }}</p>
					<p class="description" ref="clampNode" :class="{ clamp: isClamp }">{{ currentProduct?.description }}</p>
					<div class="text-box-btns">
						<button type="button" class="button-clamp" @click="isClampToglle" v-if="clampButtonVisible">{{ isClamp ? $t('product.pay_modal.show_full_description') : $t('product.pay_modal.hide') }}</button>
						<p class="text-box-quantity">
							{{ t('interface.in_stock') }}
							{{ currentProduct?.quantity }}
						</p>
					</div>
				</div>
			</div>
			<div class="price-input-box">
				<div class="price-box">
					<p>{{ $t('product.pay_modal.price_per_item') }}</p>
					<div class="text-box">
						<p class="price">{{ getCurrentCurrencyString(currentPrice) }}</p>
						<p v-if="currentProduct?.discount_price" class="old-price">{{ getCurrentCurrencyString(currentProduct?.price) }}</p>
						<!-- <p v-if="true" class="discount">-18%</p> -->
					</div>
				</div>
				<FormKit :value="1" name="count" :min="1" validation="required:trim" type="counter" :max="currentProduct?.quantity" :label="$t('interface.count')" />
			</div>
			<!-- <div class="discounts">
				<table>
					<tbody>
						<tr class="discounts-item">
							<td class="label">{{ $t('interface.discount') }}</td>
							<td class="value">0,00 ₽</td>
						</tr>
						<tr class="discounts-item">
							<td class="label">от 250 шт</td>
							<td class="value">14,50 ₽ / шт</td>
						</tr>
						<tr class="discounts-item">
							<td class="label">от 500 шт</td>
							<td class="value">14,00 ₽ / шт</td>
						</tr>
						<tr class="discounts-item">
							<td class="label">от 1000 шт</td>
							<td class="value">13,50 ₽ / шт</td>
						</tr>
					</tbody>
				</table>
			</div> -->
			<div class="checkbox-container" v-if="!isLoggedIn">
				<FormKit :v-show="!isLoggedIn" :value="true" name="checkbox_1" type="checkbox">
					<template #label="{ id, classes }">
						<label :for="id" :class="classes.label">{{ $t('interface.i_agree') }} <a :href="`/${locale}/terms-of-use.pdf`" target="_blank">{{ $t('forms.checkbox.terms_of_use') }}</a></label>
					</template>
				</FormKit>
				<FormKit :v-show="!isLoggedIn" :value="true" name="checkbox_2" type="checkbox">
					<template #label="{ id, classes }">
						<label :for="id" :class="classes.label">{{ $t('interface.i_agree') }} <a :href="`/${locale}/privacy-cookie-policy.pdf`" target="_blank">{{ $t('forms.checkbox.privacy_cookie_policy') }}</a></label>
					</template>
				</FormKit>
			</div>
			<div class="button-box">
				<button :class="{ 'btn-loaded': state.loading }" class="btn btn-pay btn-secondary" type="submit" :disabled="!value?.checkbox_1 && !isLoggedIn || !value?.checkbox_2 && !isLoggedIn || state.loading">
					{{ $t('interface.buy_any_piece_for_any', [value?.count, getCurrentCurrencyString(Number(value?.count ?? 0) * currentPrice)]) }}
				</button>
				<button :disabled="state.loading" class="btn btn-light" type="button" @click="closePopup">{{ $t('product.pay_modal.cancel') }}</button>
			</div>
		</FormKit>
</FormKitLazyProvider>

	</AtomsModal>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { useOrders } from '~/composables/orders/useOrders';
import { usePayments } from '~/composables/payment/usePayments';
import { useUserAuth } from '~/composables/user/UseUserAuth';

const { getCurrentCurrencyString } = useCurrencyHelpers()

const localePath = useLocalePath()
const { locale, t } = useI18n()
const { getUserMe } = useUserAuth()
const { user, isLoggedIn } = storeToRefs(useUserStore())
const { createOrder } = useOrders()
const { pay } = usePayments()
const { openPopup: nomoneyOpenPopup } = useNomoneyModal()
const { $toast } = useNuxtApp()
const { ecommercePush } = useYm()
const onSubmit = async (form: { count: number }) => {
	if (!currentProduct.value) return
	if (!isLoggedIn.value) {
		closePopup()
		return navigateTo(localePath('/auth'))
	}
	if (Number((form.count * currentProduct.value.price).toFixed(2)) > (user.value.user.balance ?? 0)) {
		closePopup()
		return nomoneyOpenPopup()
	}
	const res = await createOrder({ product_id: currentProduct.value.id, quantity: form.count, shop_id: currentProduct.value.shop_id })
	if (!res) return console.log(res)
	const payRes = await pay({ orderId: res.order.id, paymentId: 1 })
	if (!payRes) return console.log(payRes)
	ecommercePush('purchase', {
		"actionField": {
			"id": res?.order.id,
		},
		products: [
			{
				"id": currentProduct.value?.id,
				"name": currentProduct.value?.name,
				"price": currentProduct.value?.discount_price ?? currentProduct.value?.price,
				"category": currentProduct.value?.category.name,
				"quantity": form.count,
			}
		]
	})
	await getUserMe()
	closePopup();
	$toast.success(t('forms.success'))
	await navigateTo(localePath('/profile'))
}

const { currentProduct, closePopup } = usePayModal()
const fallbackImg = useAsset('/assets/images/png/default-imgs/product.png')
const clampNode = ref()
const { clampButtonVisible, isClamp, isClampToglle } = useClamp(clampNode)

const currentPrice = computed(() => currentProduct.value?.discount_price ?? currentProduct.value?.price ?? 0)
</script>

<style lang="scss" scoped>
.pay-modal {
	:deep(.modal-content) {
		max-width: 673px;
	}
}

.pay-modal-title-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 1;
	margin-top: -6px;
}

.pay-modal-title {
	font-weight: 800;
	font-size: 28px;
	line-height: 80%;
	letter-spacing: -0.03em;
	color: #1d254a;
}

.card-product {
	display: flex;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 12px;
	border-radius: 16px;
	box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
	contain: paint;
	margin-top: 15px;
	gap: 10px;

	.img-box {
		display: flex;
		width: 106px;
		height: 106px;
		flex-shrink: 0;
		position: relative;
		container-type: size;
		border: 1px solid #e6e6e6;
		border-radius: 8px;
		overflow: hidden;

		img {
			position: sticky;
			top: 6px;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			align-self: center;

			@container (min-height:160px) {
				align-self: flex-start;
			}
		}
	}

	.text-box {
		font-size: 16px;
		line-height: 110%;
		letter-spacing: -0.03em;
		color: rgba(29, 37, 74, 0.7);
		display: flex;
		flex-direction: column;
		gap: 5px;
		min-width: 0;

		.title {
			font-weight: 800;
			font-size: 17px;
			line-height: 120%;
			color: #1d254a;
		}

		.text-box-btns {
			display: flex;
			align-items: center;
			gap: 12px;

			.text-box-quantity {
				font-weight: 600;
				font-size: 13px;
				line-height: 80%;
				letter-spacing: -0.03em;
				color: #da4727;
			}
		}
	}

	.description {

		&.clamp {
			-webkit-line-clamp: 3;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}

	.button-clamp {
		color: #da4727;
		font-weight: 600;
		font-size: 10px;
		line-height: 80%;
		letter-spacing: -0.03em;
		cursor: pointer;
		box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
		background: rgba(218, 71, 39, 0.15);
		border: 1px solid rgba(218, 71, 39, 0.2);
		border-radius: 16px;
		padding: 3px 9px;
		width: fit-content;
	}

}

.dispute-plashka {
	display: flex;
	gap: 12px;
	align-items: center;
	border: 1px solid rgba(218, 71, 39, 0.2);
	border-radius: 16px;
	box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
	background: rgba(218, 71, 39, 0.15);
	padding: 12px 16px;
	font-weight: 600;
	font-size: 18px;
	line-height: 80%;
	letter-spacing: -0.03em;
	color: #da4727;
	margin-top: 18px;

	img {
		width: 32px;
		height: 32px;
	}
}

.price-input-box {
	display: grid;
	grid-template-columns: 1fr 250px;
	align-items: flex-start;
	grid-area: 16px;
	margin-top: 18px;

	:deep(.formkit-label) {
		color: rgba(40, 54, 71, 0.6);
		font-weight: 400;
		font-size: 17px;
		line-height: 135%;
		margin-bottom: 3px;
	}
}

.price-box {
	font-size: 17px;
	line-height: 135%;
	letter-spacing: -0.03em;
	color: rgba(40, 54, 71, 0.6);
	display: flex;
	flex-direction: column;
	gap: 10px;

	.text-box {
		display: flex;
		align-items: flex-end;
	}

	.price {
		font-weight: 700;
		font-size: 32px;
		line-height: 80%;
		color: #283647;
	}

	.old-price {
		font-size: 18px;
		line-height: 80%;
		text-decoration: line-through;
		color: #9eadbf;
		margin-left: 10px;
	}

	.discount {
		display: block;
		padding: 7px 6px 5px 6px;
		border: 1px solid rgba(255, 255, 255, 0.3);
		border-radius: 29px;
		background: #da4727;
		font-weight: 700;
		font-size: 14px;
		line-height: 80%;
		color: #fff;
		margin-left: 8px
	}
}

.discounts {
	margin-top: 14px;
	padding-top: 20px;
	border-top: 1px solid #d9d9dc;

	.discounts-item {
		font-size: 18px;
		line-height: 80%;
		letter-spacing: -0.03em;
		color: #1d254a;

		.label,
		.value {
			padding-bottom: 16px;
		}

		&:last-child {

			.label,
			.value {
				padding-bottom: 0;
			}
		}

		.value {
			font-weight: 700;
			padding-left: 21px;
		}
	}
}

.button-box {
	display: flex;
	margin: 13px 0 0;
	padding-top: 24px;
	gap: 18px;
	border-top: 1px solid #d9d9dc;

	.btn {
		width: 210px;
		text-align: center;
		padding: 16px 20px 14px;
		font-weight: 700;
		font-size: 22px;
		line-height: 80%;
		letter-spacing: -0.03em;
	}

	.btn-pay {
		flex-grow: 1;
	}
}

.checkbox-container {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

@media (max-width:1023px) {
	.pay-modal-title {
		font-size: 24px;
		align-self: flex-end;
	}

	.card-product {
		padding: 8px 8px 10px;
		gap: 10px;

		.img-box {
			container-type: normal;
			width: 80px;
			height: 80px;
			border: 1px solid rgba(0, 0, 0, 0.1);
			flex-shrink: 0;
			overflow: hidden;
			margin: auto;
		}

		.text-box {
			padding: 0;

			.text-box-btns {
				gap: 10px;

				.text-box-quantity {
					font-size: 12px;
				}
			}
		}

	}

	.dispute-plashka {
		gap: 8px;
		border-radius: 12px;
		padding: 10px;
		font-size: 16px;
		margin-top: 15px;

		img {
			width: 28px;
			height: 28px;
		}
	}

	.price-input-box {
		grid-template-columns: 1fr;
		margin-top: 15px;
	}

	.price-box {
		font-size: 16px;
		gap: 8px;
		padding-bottom: 16px;
		border-bottom: 1px solid #d9d9dc;
		margin-bottom: 16px;

		.price {
			font-size: 28px;
		}

		.old-price {
			font-size: 16px;
			margin-left: 3px;
		}

		.discount {
			padding: 5px 4px 3px 4px;
			font-size: 10px;
			margin-left: 6px
		}
	}

	.discounts {
		margin-top: 16px;

		.discounts-item {
			font-size: 16px;

			.label,
			.value {
				padding-bottom: 12px;
			}

			&:last-child {

				.label,
				.value {
					padding-bottom: 0;
				}
			}
		}
	}

	.button-box {
		display: flex;
		margin: 20px 0 14px;
		gap: 8px;
		border: none;
		padding: 0;

		.btn {
			flex: 1;
			padding: 11px 22px 9px;
			font-size: 16px;
			display: none;
		}

		.btn-pay {
			display: flex;
		}
	}

}
</style>